import Swiper, { Navigation } from 'swiper'

const desktop = document.querySelector('.partners__container--desktop')

const partnersLogo = desktop.querySelector('.partners__wrapper--logo .swiper')
const partnersAmbassadors = desktop.querySelector(
  '.partners__wrapper--ambassadors .swiper',
)
const sliderNextLogo = document.querySelector(
  '.partners__wrapper--logo .slider-btn--next',
)
const sliderPrevLogo = document.querySelector(
  '.partners__wrapper--logo .slider-btn--prev',
)
const sliderNextAmbassadors = document.querySelector(
  '.partners__wrapper--ambassadors .slider-btn--next',
)
const sliderPrevAmbassadors = document.querySelector(
  '.partners__wrapper--ambassadors .slider-btn--prev',
)

partnersLogo &&
  new Swiper(partnersLogo, {
    modules: [Navigation],
    slidesPerView: 4,
    navigation: {
      nextEl: sliderNextLogo,
      prevEl: sliderPrevLogo,
    },
  })

partnersAmbassadors &&
  new Swiper(partnersAmbassadors, {
    modules: [Navigation],
    slidesPerView: 4,
    navigation: {
      nextEl: sliderNextAmbassadors,
      prevEl: sliderPrevAmbassadors,
    },
  })
