import smoothscroll from 'smoothscroll-polyfill'

// kick off the polyfill!
smoothscroll.polyfill()

const arrowDown = document.querySelectorAll('[data-section]')
const pageWrapper = document.querySelector('.page-wrapper')
const promo = document.querySelector('#promo')
const videoBtn = document.querySelector('.video__btn')

arrowDown.forEach((arrow) => {
  const section = document.querySelector(arrow.dataset.section)
  arrow.addEventListener('click', (event) => {
    event.preventDefault()
    section.scrollIntoView({ block: 'start', behavior: 'smooth' })
  })
})

export function scrollToSection(el) {
  if (el) {
    pageWrapper?.scrollBy({
      top: el?.getBoundingClientRect().top,
      behavior: 'smooth',
    })

    // let y = el.getBoundingClientRect().top + scrollY
    // console.log(el.getBoundingClientRect())
    // pageWrapper.scroll({
    //   behavior: 'smooth',
    //   top: y,
    // })
  }
}

videoBtn.addEventListener('click', () => {
  event.preventDefault()
  scrollToSection(promo)
})
