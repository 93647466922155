import { handlersGtmEvent, metricsEvents } from './metrics-list'

const dataLayerItems = document.querySelectorAll('[data-layer]')

dataLayerItems.forEach((item) => {
  const itemDataValue = item.dataset.layer

  for (let metricsEventsKey in metricsEvents) {
    if (metricsEvents[metricsEventsKey] === itemDataValue)
      item.addEventListener('click', handlersGtmEvent[metricsEventsKey])
  }
})
