// eslint-disable-next-line no-unused-vars
import anime from 'animejs'

// const desktopTL = anime.timeline({
//   easing: 'easeOutExpo',
//   duration: 500,
//   delay: 0,
//   autoplay: false,
// })

// const mobileTL = anime.timeline({
//   easing: 'easeOutExpo',
//   duration: 500,
//   delay: 0,
//   autoplay: false,
// })

// const DESKTOP = 'desktop'
// const MOBILE = 'mobile'

// let isAnime = true
// let device = window.innerWidth >= 1000 ? DESKTOP : MOBILE

// window.addEventListener('resize', () => {
//   isAnime && initAnime()
//   if (window.innerWidth >= 1000 && device === MOBILE) {
//     device = DESKTOP
//     initAnime()
//   } else if (window.innerWidth < 1000 && device === DESKTOP) {
//     device = MOBILE
//     initAnime()
//   }
// })

// window.addEventListener('load', () => {
//   isAnime && initAnime()
// })

// setTimeout(() => {
//   isAnime && initAnime()
// }, 4000)

// const initAnime = () => {
//   isAnime = false

//   if (device === DESKTOP) {
//     mobileTL.seek(10000)
//     desktopTL
//       .add(
//         {
//           targets: '.billboard__image',
//           translateY: [10, 0],
//           opacity: [0, 1],
//           duration: 1000,
//         },
//         500
//       )
//       .add(
//         {
//           targets: '.billboard__description',
//           translateY: [10, 0],
//           opacity: [0, 1],
//           duration: 1000,
//         },
//         500
//       )
//       .add(
//         {
//           targets: '.arrow-lavand',
//           translateY: [-250, 0],
//           translateX: [339, 0],
//           opacity: [0, 1],
//           duration: 1000,
//         },
//         800
//       )
//       .add(
//         {
//           targets: '.arrow-lazure',
//           translateY: [250, 0],
//           translateX: [-339, 0],
//           opacity: [0, 1],
//           duration: 1000,
//         },
//         800
//       )
//       .add(
//         {
//           targets: '#thousand',
//           duration: 1,
//           opacity: [0, 1],
//         },
//         0
//       )
//       .add(
//         {
//           targets: '#freebet',
//           easing: 'easeInOutSine',
//           scale: [1.5, 1],
//           duration: 700,
//         },
//         500
//       )

//       .add(
//         {
//           targets: '#thousand',
//           strokeDashoffset: [anime.setDashoffset, 0],
//           easing: 'easeInOutSine',
//           duration: 3500,
//         },
//         1000
//       )
//       .play()
//   } else if (device === MOBILE) {
//     desktopTL.seek(10000)
//     mobileTL
//       .add({
//         targets: '#thousand',
//         duration: 1,
//         opacity: [0, 1],
//       })
//       .add({
//         targets: '#freebet',
//         easing: 'easeInOutSine',
//         scale: [1.5, 1],
//         duration: 700,
//       })

//       .add(
//         {
//           targets: '#thousand',
//           strokeDashoffset: [anime.setDashoffset, 0],
//           easing: 'easeInOutSine',
//           duration: 3500,
//         },
//         500
//       )
//       .play()
//   }
// }
