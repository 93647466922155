import { ClickScrollPlugin, OverlayScrollbars } from 'overlayscrollbars'

OverlayScrollbars.plugin(ClickScrollPlugin)

const wrapper = document.querySelector('.reviews')
const reviewsText = wrapper.querySelectorAll('.reviews__text-wrapper')

reviewsText.forEach((item) => {
  OverlayScrollbars(item, {
    paddingAbsolute: true,
    overflow: {
      x: 'hidden',
    },
    scrollbars: {
      pointers: ['mouse', 'touch', 'pen'],
      clickScroll: true,
    },
  })
})
